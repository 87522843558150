import { State, StateContext, Selector, Action } from '@ngxs/store';
import { Questions } from '../interface/question';
import { ApiService } from '../services/api.service';
import { trace } from '@angular/fire/performance';
import { tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  GetQuestionTypes,
  DeleteQuestionType,
  EditQuestionType,
  CreateQuestionType,
  SelectQuestionType,
  CreateQuestion,
  EditQuestion,
  DeleteQuestion,
  SelectQuestion,
  DeleteRespond,
  EditRespond,
  CreateRespond,
  SelectRespond } from './actions/question.actions';
import { Injectable } from '@angular/core';
@State<Questions>({
  name: 'Questions',
  defaults: {
  }
})
@Injectable()
export class QuestionState {
  constructor(
    private api: ApiService,
    public afAuth: AngularFireAuth,
    private snackBar: MatSnackBar,
  ) { }

  @Selector()
  static questionTypes({ questionTypes }: Questions) {
    return questionTypes;
  }

  @Selector()
  static questionSelected({ questionSelected }: Questions) {
    return questionSelected;
  }

  @Selector()
  static questionTypeSelected({ questionTypeSelected }: Questions) {
    return questionTypeSelected;
  }

  @Selector()
  static respondSelected({ respondSelected }: Questions) {
    return respondSelected;
  }

  ngxsOnInit({ }: StateContext<Questions>) {
  }

  openSnackBar = (
    message: string,
    button: string,
    duration: number,
  ) => {
    this.snackBar.open(message, button, {
      duration,
    });
  }

  @Action(DeleteRespond)
  deleteRespond(
    { patchState, getState }: StateContext<Questions>,
    { id }: DeleteRespond
  ) {
      return this.api.deleteRespond(id).pipe(
        tap(({ data: { result } }) => {
          if (result) {
            const {questionTypeSelected, questionTypes, questionSelected} = getState();
            patchState({
              questionTypes: questionTypes.map(qt =>
                questionTypeSelected.id === qt.id ?
                  {
                    ...qt,
                    Questions: qt.Questions.map(q => q.id === questionSelected.id ?
                      {...q, Respond: null} : q)
                  }
                : qt),
              questionSelected : {...questionSelected, Respond: null},
              questionTypeSelected : {
                ...questionTypeSelected,
                Questions: questionTypeSelected.Questions.map(q => q.id === questionSelected.id ?
                  {...q, Respond: null} : q)
              }
            });
          }
        }),
        trace('DeleteRespond'),
      );
  }

  @Action(EditRespond)
  editRespond(
    { patchState, getState }: StateContext<Questions>,
    { data }: EditRespond
  ) {
    return this.api.editRespond(data).pipe(
      tap(({ data: { result } }) => {
        if (result) {
          const {questionTypeSelected, questionTypes, questionSelected} = getState();
          patchState({
            questionTypes: questionTypes.map(qt =>
              questionTypeSelected.id === qt.id ?
                {
                  ...qt,
                  Questions: qt.Questions.map(q => q.id === questionSelected.id ?
                    {...q, Respond: result} : q)
                }
              : qt),
            questionTypeSelected: {
              ...questionTypeSelected,
              Questions: questionTypeSelected.Questions.map(q => q.id === questionSelected.id ?
                {...q, Respond: result} : q)
            },
            questionSelected : {...questionSelected, Respond: result},
          });
        }
      }),
      trace('EditRespond'),
    );
  }

  @Action(CreateRespond)
  createRespond(
    { patchState, getState }: StateContext<Questions>,
    { data }: CreateRespond
  ) {
    return this.api.createRespond(data).pipe(
      tap(({ data: { result } }) => {
        if (result) {
          const {questionTypeSelected, questionTypes, questionSelected, respondSelected} = getState();
          patchState({
            questionTypes: questionTypes.map(qt =>
              questionTypeSelected.id === qt.id ? {
                ...qt, Questions: qt.Questions.map(q => q.id === questionSelected.id ? {...questionSelected, Respond: result} : q)
              } : qt),
            questionTypeSelected: {
              ...questionTypeSelected,
              Questions: questionTypeSelected.Questions.map(q => q.id === questionSelected.id ?
                 {...questionSelected, Respond: result} : q)
            },
            questionSelected: {
              ...questionSelected,
              Respond: result,
            },
          });
        }
      }),
      trace('CreateRespond'),
    );
  }

  @Action(SelectRespond)
  selectRespond(
    { patchState }: StateContext<Questions>,
    { respondSelected }: SelectRespond
  ) {
    patchState({ respondSelected });
  }

  @Action(DeleteQuestion)
  deleteQuestion(
    { patchState, getState }: StateContext<Questions>,
    { id }: DeleteQuestion
  ) {
      return this.api.deleteQuestion(id).pipe(
        tap(({ data: { result } }) => {
          if (result) {
            const {questionTypeSelected, questionTypes, questionSelected} = getState();
            patchState({
              questionTypes: questionTypes.map(qt =>
                questionTypeSelected.id === qt.id ?
                  {
                    ...qt,
                    Questions: qt.Questions.filter(q => q.id !== id)
                  }
                : qt),
              questionSelected : null,
              questionTypeSelected : {
                ...questionTypeSelected,
                Questions: questionTypeSelected.Questions.filter(q => q.id !== id)
              }
            });
          }
        }),
        trace('DeleteQuestion'),
      );
  }

  @Action(EditQuestion)
  editQuestion(
    { patchState, getState }: StateContext<Questions>,
    { data }: EditQuestion
  ) {
    return this.api.editQuestion(data).pipe(
      tap(({ data: { result } }) => {
        if (result) {
          const {questionTypeSelected, questionTypes} = getState();
          patchState({
            questionTypes: questionTypes.map(qt =>
              questionTypeSelected.id === qt.id ?
                {
                  ...qt,
                  Questions: qt.Questions.map(q => q.id === result.id ? result : q)
                }
              : qt),
            // questionSelected: result,
            questionTypeSelected : {
              ...questionTypeSelected, Questions: questionTypeSelected.Questions.map(q => q.id === result.id ? result : q)}
          });
        }
      }),
      trace('EditQuestion'),
    );
  }

  @Action(CreateQuestion)
  createQuestion(
    { patchState, getState }: StateContext<Questions>,
    { data }: CreateQuestion
  ) {
    return this.api.createQuestion(data).pipe(
      tap(({ data: { result } }) => {
        if (result) {
          const {questionTypeSelected, questionTypes} = getState();
          patchState({
            questionTypes: questionTypes.map(qt =>
              questionTypeSelected.id === qt.id ? {...qt, Questions: [result, ...qt.Questions]} : qt),
            questionTypeSelected: {...questionTypeSelected, Questions: [result, ...questionTypeSelected.Questions]}
          });
        }
      }),
      trace('CreateQuestion'),
    );
  }

  @Action(SelectQuestion)
  selectQuestion(
    { patchState }: StateContext<Questions>,
    { questionSelected }: SelectQuestion
  ) {
    patchState({ questionSelected });
  }

  @Action(SelectQuestionType)
  selectQuestionType(
    { patchState }: StateContext<Questions>,
    { questionTypeSelected }: SelectQuestionType
  ) {
    patchState({ questionTypeSelected });
  }

  @Action(DeleteQuestionType)
  deleteQuestionType(
    { patchState, getState }: StateContext<Questions>,
    { id }: DeleteQuestionType
  ) {
      return this.api.deleteQuestionType(id).pipe(
        tap(({ data: { result } }) => {
          if (result) {
            const { questionTypes } = getState();
            patchState({
              questionTypes: questionTypes.filter(qt => Number(qt.id) !== Number(id)),
              questionTypeSelected: null,
            });
          }
        }),
        trace('DeleteQuestionType'),
      );
  }

  @Action(EditQuestionType)
  editQuestionType(
    { patchState, getState }: StateContext<Questions>,
    { id, data }: EditQuestionType
  ) {
    return this.api.editQuestionType(data).pipe(
      tap(({ data: { result } }) => {
        if (result) {
          patchState({
            questionTypes: getState().questionTypes.map(qt => Number(qt.id) === Number(id) ? result : qt),
            // questionTypeSelected: result,
          });
        }
      }),
      trace('EditQuestionType'),
    );
  }

  @Action(CreateQuestionType)
  createQuestionType(
    { patchState, getState }: StateContext<Questions>,
    { data }: CreateQuestionType
  ) {
    return this.api.createQuestionType(data).pipe(
      tap(({ data: { result } }) => {
        if (result) {
          patchState({questionTypes: getState().questionTypes ? [...getState().questionTypes, result] : [result]});
        }
      }),
      trace('EditQuestionType'),
    );
  }

  @Action(GetQuestionTypes)
  getQuestionTypes(
    { patchState, getState }: StateContext<Questions>,
    { placeId }: GetQuestionTypes
  ) {
      return this.api.questionTypesByPlace(placeId).pipe(
        tap(({ data: { questionTypes } }) => {
          if (questionTypes && questionTypes.length > 0) {
            patchState({questionTypes});
          }
        }),
        trace('GetQuestionTypes'),
      );
  }
}
