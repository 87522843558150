import gql from 'graphql-tag';


// export const validatePhoneNumber = gql`
//   query validatePhoneNumber($phoneNumber: String!) {
//     invalid: validatePhoneNumber(phoneNumber: $phoneNumber)
//   }`;

// export const getUserByToken = gql`
//   query getUserByToken($token: String!) {
//     user: getUserByToken(token: $token) {

//     }
//   }`;

export const getAllCountries = gql`
  query getAllCountries {
    countries: getAllCountries {
      id
      Name_Country
      PhoneCode_Country
      Iso_Country
    }
  }`;

export const getPlacesByUserAndModule = gql`
  query getPlacesByUserAndModule($userId: ID!, $moduleId: ID!) {
    places: getPlacesByUserAndModule(FK_User: $userId, FK_Module: $moduleId) {
      id
      Name_Place
      Description_Place
    }
  }
`;

export const getUsersWithMessages = gql`
  query getUsersWithMessages($placeId: String!) {
    result : getUsersWithMessages(placeId: $placeId) {
      UsersAnonymous {
        id
        Name
        Email
        Messages {
          id
          File {
            id
            FileType {
              id
            }
          }
          Admin {
            id
            Email
            Name
            Given_Name
            Family_Name
            Picture
          }
          Message
          Read
          Created_At
        }
      }
      UsersGoogleAuth {
        id
        Email
        Name
        Given_Name
        Family_Name
        Picture
        Messages {
          id
          File {
            id
            FileType {
              id
            }
          }
          Admin {
            id
            Email
            Name
            Given_Name
            Family_Name
            Picture
          }
          Message
          Read
          Created_At
        }
      }
    }
}`;

export const getMessages = gql`
  query getMessages($userId: String, $anonymousId: String, $placeId: String!) {
    Messages : getMessages(userId: $userId, anonymousId: $anonymousId, placeId: $placeId) {
      id
      File {
        id
        FileType {
          id
        }
      }
      Admin {
        id
        Email
        Name
        Picture
      }
      Message
      Read
      Created_At
    }
}`;

export const questionTypesByPlace = gql`
  query questionTypesByPlace($placeId: String!) {
    questionTypes : questionTypesByPlace(placeId: $placeId) {
      id
      Type
      Questions {
        id
        Question
        Created_At
        Updated_At
        Admin {
          id
          Name
          Picture
        }
        Respond {
          id
          Respond
          Created_At
          Updated_At
          Useful
          Useless
          Admin {
            id
            Name
            Picture
          }
        }
      }
      Created_At
      Updated_At
    }
}`;
