import gql from "graphql-tag";

export const userByUID = gql`
  query userByUID($uid: ID) {
    firebaseUser: userByUID(uid: $uid) {
      id
      Email
      Name
      Given_Name
      AcceptTerms
      ShowGuide
      Family_Name
      Picture
      Locale
      Verified_Email
      UID
      Phone_Number
      Provider_Id
      TavuelUser {
        id
        NickName_User
        UserState {
          id
          Name_UserState
        }
        NotificationDevices {
          id
          Token_NotificationDevice
          State_NotificationDevice
        }
        Sudo
        Person {
          id
          Name_Person
          Lastname_Person
          Email
          Phone
          ProfileImage
          DocumentId_Person
          Files {
            id
            Route_File
            Created_At
            FileType {
              id
              Name_FileType
            }
          }
        }
      }
    }
  }
`;

export const suscribeToChatNotifications = gql`
  mutation suscribeToChatNotifications(
    $notificationDevice: NotificationDeviceInput
  ) {
    notificationDevice: suscribeToChatNotifications(
      notificationDevice: $notificationDevice
    ) {
      id
      Token_NotificationDevice
      State_NotificationDevice
    }
  }
`;

export const suscribeToChatAdminNotifications = gql`
  mutation suscribeToChatAdminNotifications(
    $notificationDevice: NotificationDeviceInput
  ) {
    notificationDevice: suscribeToChatAdminNotifications(
      notificationDevice: $notificationDevice
    ) {
      id
      Token_NotificationDevice
      State_NotificationDevice
    }
  }
`;

export const firebaseAuth = gql`
  mutation firebaseAuth($user: GoogleAuthInput) {
    firebaseUser: firebaseAuth(user: $user) {
      id
      UID
      Token
      Email
      Name
      Given_Name
      AcceptTerms
      ShowGuide
      Family_Name
      Picture
      Locale
      Verified_Email
      Refresh_Token
      Created_At
      Updated_At
    }
  }
`;

export const registerPerson = gql`
  mutation registerPerson($user: GoogleAuthInput) {
    registerPerson: registerPerson(user: $user) {
      id
      Email
      Name
      Given_Name
      AcceptTerms
      ShowGuide
      Family_Name
      Picture
      Locale
      Verified_Email
      UID
      Phone_Number
      Provider_Id
      TavuelUser {
        id
        NickName_User
        Person {
          id
          Name_Person
          Lastname_Person
          Email
          Phone
          ProfileImage
          Country {
            id
            Name_Country
          }
          Files {
            id
            Route_File
            Created_At
            FileType {
              id
              Name_FileType
            }
          }
        }
      }
    }
  }
`;

export const registerUser = gql`
  mutation registerUser($user: GoogleAuthInput) {
    registerUser: registerUser(user: $user) {
      id
      Token
      Email
      Name
      Given_Name
      AcceptTerms
      ShowGuide
      Family_Name
      Picture
      Locale
      Verified_Email
      Refresh_Token
      Created_At
      Updated_At
    }
  }
`;

export const sendMessage = gql`
  mutation sendMessage($message: String!) {
    result: sendMessage(message: $message) {
      id
      Admin {
        id
        Email
        Name
        Picture
      }
      GoogleAuth {
        id
        Name
        Given_Name
        Picture
        Messages {
          id
          File {
            id
            FileType {
              id
            }
          }
          Admin {
            id
            Email
            Name
            Picture
          }
          Message
          Created_At
        }
      }
      UserAnonymous {
        id
        Name
        Email
        Messages {
          id
          File {
            id
            FileType {
              id
            }
          }
          Admin {
            id
            Email
            Name
            Picture
          }
          Message
          Created_At
        }
      }
      File {
        id
      }
      Message
      Created_At
      Updated_At
    }
  }
`;

export const createQuestion = gql`
  mutation createQuestion($data: QuestionInput!) {
    result: createQuestion(data: $data) {
      id
      Question
      Admin {
        id
        Email
        Name
        Picture
      }
      Respond {
        id
        Respond
        Admin {
          id
          Email
          Name
          Picture
        }
        Useful
        Useless
        Created_At
        Updated_At
      }
      Created_At
      Updated_At
    }
  }
`;

export const editQuestion = gql`
  mutation editQuestion($data: QuestionInput!) {
    result: editQuestion(data: $data) {
      id
      Question
      Admin {
        id
        Email
        Name
        Picture
      }
      Respond {
        id
        Respond
        Admin {
          id
          Email
          Name
          Picture
        }
        Useful
        Useless
        Created_At
        Updated_At
      }
      Created_At
      Updated_At
    }
  }
`;

export const readMessage = gql`
  mutation readMessage($message: MessageInput!) {
    result: readMessage(message: $message) {
      id
      Admin {
        id
        Email
        Name
        Picture
      }
      GoogleAuth {
        id
        Name
        Given_Name
        Picture
        Messages {
          id
          File {
            id
            FileType {
              id
            }
          }
          Admin {
            id
            Email
            Name
            Picture
          }
          Message
          Created_At
        }
      }
      UserAnonymous {
        id
        Name
        Email
        Messages {
          id
          File {
            id
            FileType {
              id
            }
          }
          Admin {
            id
            Email
            Name
            Picture
          }
          Message
          Created_At
        }
      }
      Message
      Read
      Created_At
      Updated_At
    }
  }
`;

export const deleteQuestion = gql`
  mutation deleteQuestion($id: ID!) {
    result: deleteQuestion(id: $id)
  }
`;

export const createQuestionType = gql`
  mutation createQuestionType($data: QuestionTypeInput!) {
    result: createQuestionType(data: $data) {
      id
      Type
      Created_At
      Updated_At
      Questions {
        id
        Question
        Admin {
          id
          Email
          Name
          Picture
        }
        Respond {
          id
          Respond
          Admin {
            id
            Email
            Name
            Picture
          }
          Useful
          Useless
          Created_At
          Updated_At
        }
        Created_At
        Updated_At
      }
    }
  }
`;

export const editQuestionType = gql`
  mutation editQuestionType($data: QuestionTypeInput!) {
    result: editQuestionType(data: $data) {
      id
      Type
      Created_At
      Updated_At
      Questions {
        id
        Question
        Admin {
          id
          Email
          Name
          Picture
        }
        Respond {
          id
          Respond
          Admin {
            id
            Email
            Name
            Picture
          }
          Useful
          Useless
          Created_At
          Updated_At
        }
        Created_At
        Updated_At
      }
    }
  }
`;

export const deleteQuestionType = gql`
  mutation deleteQuestionType($id: ID!) {
    result: deleteQuestionType(id: $id)
  }
`;

export const marckUseful = gql`
  mutation marckUseful($id: ID!) {
    result: marckUseful(id: $id) {
      id
      Respond
      Admin {
        id
        Email
        Name
        Picture
      }
      Useful
      Useless
      Created_At
      Updated_At
    }
  }
`;

export const marckUseless = gql`
  mutation marckUseless($id: ID!) {
    result: marckUseless(id: $id) {
      id
      Respond
      Admin {
        id
        Email
        Name
        Picture
      }
      Useful
      Useless
      Created_At
      Updated_At
    }
  }
`;

export const deleteRespond = gql`
  mutation deleteRespond($id: ID!) {
    result: deleteRespond(id: $id)
  }
`;

export const createRespond = gql`
  mutation createRespond($data: RespondInput!) {
    result: createRespond(data: $data) {
      id
      Respond
      Admin {
        id
        Email
        Name
        Picture
      }
      Useful
      Useless
      Created_At
      Updated_At
    }
  }
`;

export const editRespond = gql`
  mutation editRespond($data: RespondInput!) {
    result: editRespond(data: $data) {
      id
      Respond
      Admin {
        id
        Email
        Name
        Picture
      }
      Useful
      Useless
      Created_At
      Updated_At
    }
  }
`;
