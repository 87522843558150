import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { HttpClient } from "@angular/common/http";
import {
  // getUserByToken
  getUsersWithMessages,
  getMessages,
  getAllCountries,
  questionTypesByPlace,
  getPlacesByUserAndModule,
} from "./graphql/querys";
import {
  suscribeToChatNotifications,
  firebaseAuth,
  userByUID,
  registerPerson,
  registerUser,
  sendMessage,
  readMessage,
  createQuestion,
  editQuestion,
  deleteQuestion,
  createQuestionType,
  editQuestionType,
  deleteQuestionType,
  marckUseful,
  marckUseless,
  createRespond,
  editRespond,
  deleteRespond,
  suscribeToChatAdminNotifications,
} from "./graphql/mutations";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  context = (token) => {
    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return {};
  };

  //////////////////////////////////////
  /////////////QUERIES//////////////////
  //////////////////////////////////////

  // validatePhoneNumber = (token, phoneNumber) =>
  //   this.apollo.query<any>({
  //     query: validatePhoneNumber,
  //     variables: { phoneNumber },
  //     context: this.context(token)
  //   })

  // getUserByToken = (token) =>
  //   this.apollo.query<any>({
  //     query: getUserByToken,
  //     variables: { token },
  //     context: this.context(token)
  //   })

  getUsersWithMessages = (placeId) =>
    this.apollo.use("chat").query<any>({
      query: getUsersWithMessages,
      variables: { placeId },
      // context: this.context(token)
    });

  getMessages = (userId, anonymousId, placeId) =>
    this.apollo.use("chat").query<any>({
      query: getMessages,
      variables: { userId, anonymousId, placeId },
    });

  getPlacesByUserAndModule = (userId, moduleId, token) =>
    this.apollo
      .query<any>({
        context: this.context(token),
        query: getPlacesByUserAndModule,
        variables: { userId, moduleId },
    })

  getAllCountries = () =>
    this.apollo.query<any>({
      query: getAllCountries,
    });

  questionTypesByPlace = (placeId) =>
    this.apollo.use("chat").query<any>({
      query: questionTypesByPlace,
      variables: { placeId },
    });

  ///////////////////////////////////////
  /////////////MUTATION//////////////////
  //////////////////////////////////////

  sendMessage = (message) =>
    this.apollo.use("chat").mutate<any>({
      mutation: sendMessage,
      variables: { message },
    });

  readMessage = ( message ) =>
    this.apollo.use("chat").mutate<any>({
      mutation: readMessage,
      variables: { message },
    });

  createQuestion = (data) =>
    this.apollo.use("chat").mutate<any>({
      mutation: createQuestion,
      variables: { data },
    });

  editQuestion = (data) =>
    this.apollo.use("chat").mutate<any>({
      mutation: editQuestion,
      variables: { data },
    });

  deleteQuestion = (id) =>
    this.apollo.use("chat").mutate<any>({
      mutation: deleteQuestion,
      variables: { id },
    });

  createQuestionType = (data) =>
    this.apollo.use("chat").mutate<any>({
      mutation: createQuestionType,
      variables: { data },
    });

  editQuestionType = (data) =>
    this.apollo.use("chat").mutate<any>({
      mutation: editQuestionType,
      variables: { data },
    });

  deleteQuestionType = (id) =>
    this.apollo.use("chat").mutate<any>({
      mutation: deleteQuestionType,
      variables: { id },
    });

  marckUseful = (id) =>
    this.apollo.use("chat").mutate<any>({
      mutation: marckUseful,
      variables: { id },
    });

  marckUseless = (id) =>
    this.apollo.use("chat").mutate<any>({
      mutation: marckUseless,
      variables: { id },
    });

  createRespond = (data) =>
    this.apollo.use("chat").mutate<any>({
      mutation: createRespond,
      variables: { data },
    });

  editRespond = (data) =>
    this.apollo.use("chat").mutate<any>({
      mutation: editRespond,
      variables: { data },
    });

  deleteRespond = (id) =>
    this.apollo.use("chat").mutate<any>({
      mutation: deleteRespond,
      variables: { id },
    });

  registerPerson = (token, user) =>
    this.apollo.mutate<any>({
      mutation: registerPerson,
      context: this.context(token),
      variables: { user },
    });

  registerUser = (user) =>
    this.apollo.mutate<any>({
      mutation: registerUser,
      variables: { user },
    });

  suscribeToChatNotifications = (token, notificationDevice) =>
    this.apollo.use("chat").mutate<any>({
      mutation: suscribeToChatNotifications,
      variables: { notificationDevice },
      context: this.context(token),
    });

  suscribeToChatAdminNotifications = (token, notificationDevice) =>
    this.apollo.use("chat").mutate<any>({
      mutation: suscribeToChatAdminNotifications,
      variables: { notificationDevice },
      context: this.context(token),
    });

  firebaseAuth = (user) =>
    this.apollo.mutate<any>({
      mutation: firebaseAuth,
      variables: { user },
    });

  userByUID = (token, uid) =>
    this.apollo.mutate<any>({
      mutation: userByUID,
      context: this.context(token),
      variables: { uid },
    });
}
