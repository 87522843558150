import { Observable } from "rxjs";
import { FirebaseUser, UserAnonymous, MessageInput } from "src/app/interface/user";

export class FirebaseAuth {
  static readonly type = "[User] FirebaseAuth]";
  constructor(public readonly user: any, public readonly ghostUID?: any) {}
}

export class SendMessage {
  static readonly type = "[User] SendMessage]";
  constructor(public readonly message: string) {}
}

export class ReadMessage {
  static readonly type = "[User] ReadMessage]";
  constructor(public readonly data: MessageInput) {}
}

export class AddMessage {
  static readonly type = "[User] AddMessage]";
  constructor(
    public readonly message: string,
    public readonly isNew?: boolean
  ) {}
}

export class GetUsersWithMessages {
  static readonly type = "[User] GetUsersWithMessages]";
}

export class SelectUser {
  static readonly type = "[User] SelectUser]";
  constructor(
    public readonly UserGoogleAuth?: UserAnonymous,
    public readonly UserAnonymous?: FirebaseUser
  ) {}
}

export class Login {
  static readonly type = "[User] Login]";
  constructor(public readonly credentials: { email: any; password: any }) {}
}

export class SignOut {
  static readonly type = "[User] SignOut]";
  constructor() {}
}

export class WatchIdToken {
  static readonly type = "[User] WatchIdToken]";
}

export class UpdateUserSession {
  static readonly type = "[User] UpdateUserSession]";
  constructor(
    public readonly info: {
      firebaseUser: FirebaseUser;
      token: string;
      isGhost: string;
      ShowGuide?: boolean;
    }
  ) {}
}

export class SusbcribeToFCM {
  static readonly type = "[FCM] SusbcribeToFCM]";
  constructor(public readonly firstInt?: boolean) {}
}

export class UserByUID {
  static readonly type = "[User] UserByUID]";
  constructor(public readonly token: string, public readonly uid: string) {}
}

export class GoogleLogin {
  static readonly type = "[User] GoogleLogin]";
  constructor() {}
}

export class SuscribeToChatAdminNotifications {
  static readonly type = "[FCM] SuscribeToChatAdminNotifications]";
  constructor(public readonly fcmToken: string) {}
}

export class ResetPasswordEmail {
  static readonly type = "[User] ResetPasswordEmail]";
  constructor(public readonly email: string) {}
}

export class AllCountries {
  static readonly type = "[User] AllCountries]";
}

export class RegisterPerson {
  static readonly type = "[User] RegisterPerson]";
  constructor(public readonly user: any) {}
}

export class CreateAcount {
  static readonly type = "[User] CreateAcount]";
  constructor(public readonly user: any) {}
}

export class getPlacesByUserAndModule {
  static readonly type = "[User] getPlacesByUserAndModule]";
  constructor() {}
}

export class PlaceID {
  static readonly type = '[User] PlaceID]';
  constructor(public readonly placeId: number) { }
}
