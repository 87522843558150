import { State, Action, StateContext, Selector, NgxsOnInit } from '@ngxs/store';
import { UI } from '../interface/ui';
import { Platform } from '@angular/cdk/platform';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { SetLoading } from './actions/ui.actions';
import { Injectable } from '@angular/core';

let win: any;

export class SelectedColor {
  static readonly type = '[UI] SelectedColor]';
  constructor(public readonly color: string) { }
}

export class SetDrawer {
  static readonly type = '[UI] SetDrawer]';
  constructor(public readonly drawer: any) { }
}

export class InstallApp {
  static readonly type = '[UI] InstallApp]';
}

export class Loader {
  static readonly type = '[UI] Loader]';
  constructor(public readonly LoaderState: number) { }
}

export class CheckUpdated {
  static readonly type = '[UI] CheckUpdated]';
}

export class VerifyInstallPrompt {
  static readonly type = '[UI] VerifyInstallPrompt]';
}

export class ShareContent {
  static readonly type = '[UI] ShareContent]';
  constructor(
    public readonly title: string,
    public readonly text: string,
    public readonly url: string,
  ) { }
}

export class Vibrate {
  static readonly type = '[UI] Vibrate]';
  constructor(public readonly duration: number) { }
}

@State<UI>({
  name: 'ui',
  defaults: {
    loadingData: {
      loading: false,
      yProgress: 0
    }
  }
})

@Injectable()
export class UIState implements NgxsOnInit {

  window: any;

  @Selector()
  static device({ device }: UI) {
    return device;
  }

  @Selector()
  static selectedColor({ selectedColor }: UI) {
    return selectedColor;
  }

  @Selector()
  static install({ install }: UI) {
    return install;
  }

  @Selector()
  static share({ share }: UI) {
    return share;
  }

  @Selector()
  static LoaderState({ LoaderState }: UI) {
    return LoaderState;
  }

  @Selector()
  static loadingData({ loadingData }: UI) {
    return loadingData;
  }

  constructor(
    public platform: Platform,
    private snackBar: MatSnackBar,
    private swUpdate: SwUpdate,
  ) { }

  async ngxsOnInit({ dispatch }: StateContext<UI>) {
    dispatch(new VerifyInstallPrompt());
    dispatch(new CheckUpdated());
  }

  @Action(VerifyInstallPrompt)
  verifyInstallPrompt({ patchState }: StateContext<UI>) {
    win = window.navigator;
    let share = false;
    if (win.share) {
      share = true;
    }
    window.addEventListener('beforeinstallprompt', (install: any) => {
      console.log('success', install);
      install.preventDefault();
      patchState({ install });
    });
    const { userAgent, platform } = navigator;
    patchState({ device: { ...this.platform, userAgent, name: platform }, share });
  }

  @Action(CheckUpdated)
  checkUpdated({ }: StateContext<UI>) {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        const snackBarRef = this.snackBar.open('Nueva versión disponible  😄 ', 'OK', {
          panelClass: ['custom-snackbar']
        });
        snackBarRef.onAction().subscribe(() => {
          window.location.reload();
        });
      });
    }
  }

  @Action(SelectedColor)
  SelectedColor({ patchState }: StateContext<UI>, { color }: SelectedColor) {
    patchState({ selectedColor: color });
  }

  @Action(SetDrawer)
  SetDrawer({ patchState }: StateContext<UI>, { drawer }: SetDrawer) {
    patchState({ sidenav: drawer });
  }


  @Action(InstallApp)
  async installApp({ getState, patchState }: StateContext<UI>) {
    const { install } = getState();
    if (install !== undefined) {
      install.prompt();

      const choiceResult = await install.userChoice;
      console.log(choiceResult.outcome);
      if (choiceResult.outcome === 'dismissed') {
        console.log('User cancelled home screen install');
      } else {
        console.log('User added to home screen');
      }
      patchState({ install: null });
    }
  }

  @Action(Loader)
  Loader({ patchState }: StateContext<UI>, { LoaderState }: Loader) {
    patchState({ LoaderState });
  }

  @Action(ShareContent)
  shareContent({ }: StateContext<UI>, { title, text, url }: ShareContent) {
    win = window.navigator;
    win.share({
      title,
      text,
      url
    }).then(() => {
      this.snackBar.open('Gracias por compartir', '😄', {
        duration: 3000,
        panelClass: ['custom-snackbar']
      });
      if (window.navigator.vibrate) {
        window.navigator.vibrate(30);
      }
    })
      .catch(err => {
        this.snackBar.open('Te agredecemos el intento', '😥', {
          duration: 3000,
          panelClass: ['custom-snackbar-error']
        });
        if (window.navigator.vibrate) {
          window.navigator.vibrate(30);
        }
      });
  }

  @Action(Vibrate)
  Vibrate(
    { duration }: Vibrate
  ) {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(duration);
    }
  }

  @Action(SetLoading)
  setLoading({ patchState, getState }: StateContext<UI>, data: SetLoading) {
    const { loadingData } = getState();
    patchState({ loadingData: { ...loadingData, ...data } });
  }
}
