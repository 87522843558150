import { FcmService } from "../services/fcm.service";
import { State, StateContext, Selector, Action, Store } from "@ngxs/store";
import { User } from "../interface/user";
import { ApiService } from "../services/api.service";
import { AngularFirePerformance, trace } from "@angular/fire/performance";
import { tap, catchError, finalize } from "rxjs/operators";

const cloneDeep = require('clone-deep');
import {
  SendMessage,
  ReadMessage,
  AddMessage,
  GetUsersWithMessages,
  SelectUser,
  UpdateUserSession,
  WatchIdToken,
  UserByUID,
  SignOut,
  GoogleLogin,
  FirebaseAuth,
  SusbcribeToFCM,
  Login,
  SuscribeToChatAdminNotifications,
  ResetPasswordEmail,
  AllCountries,
  RegisterPerson,
  CreateAcount,
  getPlacesByUserAndModule,
  PlaceID,
} from "./actions/user.actions";
import { Navigate } from "@ngxs/router-plugin";
import { AngularFireAuth } from "@angular/fire/auth";
import { of } from "rxjs";
import { auth } from "firebase";
import { MatSnackBar } from "@angular/material/snack-bar";
const CryptoJS = require("crypto-js");
import { Socket } from "ngx-socket-io";
import { environment } from "src/environments/environment.prod";
import { Injectable } from "@angular/core";
@State<User>({
  name: "user",
  defaults: {
    UserSelected: null,
  },
})
@Injectable()
export class UserState {
  constructor(
    private api: ApiService,
    public afAuth: AngularFireAuth,
    private fcm: FcmService,
    private store: Store,
    private socket: Socket,
    private snackBar: MatSnackBar,
    private afp: AngularFirePerformance,
  ) {}

  places = [];

  private lastPlaceSocket = null;
  private lastChatSocket = null;

  @Selector()
  static UsersWithMessages({ UsersWithMessages }: User) {
    return UsersWithMessages;
  }

  @Selector()
  static firebaseUser({ firebaseUser }: User) {
    return firebaseUser;
  }

  @Selector()
  static devideAdded({ devideAdded }: User) {
    return devideAdded;
  }

  @Selector()
  static token({ token }: User) {
    return token;
  }

  @Selector()
  static UID({ firebaseUser: { UID } }: User) {
    return UID;
  }

  @Selector()
  static meUser({ firebaseUser: { TavuelUser } }: User) {
    return TavuelUser;
  }

  @Selector()
  static countries({ countries }: User) {
    return countries;
  }

  @Selector()
  static UserSelected({ UserSelected }: User) {
    return UserSelected;
  }

  @Selector()
  static Places({ Places }: User) {
    return Places;
  }

  @Selector()
  static placeId({ placeId }: User) {
    return placeId;
  }

  ngxsOnInit({ dispatch }: StateContext<User>) {
    this.afAuth.useDeviceLanguage();
    this.fcm.receiveMessages();
    dispatch(new WatchIdToken());
  }

  openSnackBar = (message: string, button: string, duration: number) => {
    this.snackBar.open(message, button, {
      duration,
    });
  };

  @Action(UpdateUserSession)
  UpdateUserSession(
    { patchState }: StateContext<User>,
    { info }: UpdateUserSession
  ) {
    const { firebaseUser, token, isGhost, ShowGuide } = info;
    patchState({ firebaseUser, token, isGhost });
  }

  @Action(WatchIdToken)
  watchIdToken({ dispatch }: StateContext<User>) {
    this.afAuth.useDeviceLanguage();
    return this.afAuth.idTokenResult.pipe(
      tap((idToken) => {
        if (idToken) {
          const {
            claims: { user_id },
            token,
            signInProvider,
          } = idToken;
          if (signInProvider === "anonymous") {
            const info = {
              token,
              isGhost: "1",
              ShowGuide: true,
              firebaseUser: { UID: user_id },
            };
            dispatch(new UpdateUserSession(info));
          } else {
            dispatch(new UserByUID(token, user_id));
          }
        } else {
          const info = {
            token: null,
            isGhost: "1",
            ShowGuide: true,
            firebaseUser: null,
          };
          dispatch(new UpdateUserSession(info));
        }
      }),
      trace("WatchIdToken")
    );
  }

  @Action(UserByUID)
  userByUID({ dispatch }: StateContext<User>, { token, uid }: UserByUID) {
    return this.api.userByUID(token, uid).pipe(
      tap(({ data: { firebaseUser } }) => {
        if (firebaseUser) {
          if (firebaseUser.TavuelUser) {
            const info = {
              firebaseUser,
              token,
              isGhost: "0",
              ShowGuide: firebaseUser.ShowGuide,
            };
            dispatch(new UpdateUserSession(info));
            dispatch(new GetUsersWithMessages());
            this.socket.fromEvent("new-message").subscribe((res: string) => {
            });
          } else {
            const info = {
              firebaseUser,
              token,
              isGhost: "0",
              ShowGuide: false,
            };
            dispatch(new UpdateUserSession(info));
            dispatch(new Navigate(["/profile"]));
          }
        } else {
          const info = {
            firebaseUser: { UID: uid },
            token,
            isGhost: "1",
            ShowGuide: false,
          };
          dispatch(new UpdateUserSession(info));
        }
      }),
      trace("UserByUID"),
      catchError(({ graphQLErrors }) => {
        const [{ message }] = graphQLErrors;
        this.snackBar.open(graphQLErrors, "🙄", {
          duration: 3000,
          panelClass: ["custom-snackbar-error"],
        });
        return of(null);
      })
    );
  }

  @Action(SignOut)
  signOut({ setState }: StateContext<User>) {
    return this.afAuth.signOut().then(() => {
      setState({
        isGhost: "1",
        firebaseUser: null,
        UserSelected: null,
        UsersWithMessages: null,
      });
    });
  }

  @Action(AllCountries)
  allCountries({ patchState }: StateContext<User>) {
    return this.api.getAllCountries().pipe(
      tap(({ data: { countries } }) => {
        patchState({ countries });
      }),
      trace("AllCountries")
    );
  }

  @Action(GoogleLogin)
  googleLogin({ dispatch }: StateContext<User>, {}: GoogleLogin) {
    this.afAuth.signInWithPopup(new auth.GoogleAuthProvider()).then(
      (result) => {
        dispatch(new FirebaseAuth(result));
        this.openSnackBar("Bienvenido", "😄", 3000);
      },
      (err) => {
        this.openSnackBar(
          "Intenta desde otro navegador o con nuestro inicio de sesión",
          "OK",
          7000
        );
      }
    );
  }

  @Action(FirebaseAuth)
  firebaseAuth({}: StateContext<User>, { user, ghostUID }: FirebaseAuth) {
    const {
      additionalUserInfo: { providerId, profile },
      user: { uid, email, emailVerified, phoneNumber, displayName, photoURL },
    } = user;
    let signInUser: any = {
      Email: email,
      Picture: photoURL,
      Name: displayName,
      Verified_Email: emailVerified,
      Phone_Number: phoneNumber,
      UID: uid,
      Provider_Id: providerId,
      GhostUID: ghostUID,
      AcceptTerms: true,
    };
    if (profile) {
      const { family_name, given_name, locale } = profile;
      signInUser = {
        ...signInUser,
        Locale: locale || navigator.language,
        Given_Name: given_name,
        Family_Name: family_name,
      };
    } else {
      signInUser = {
        ...signInUser,
        Locale: navigator.language,
      };
    }
    return this.api.firebaseAuth(signInUser).pipe(
      catchError((eerr) => {
        this.snackBar.open("Error al autenticar al usuario", "OK", {
          duration: 3000,
          panelClass: ["custom-snackbar-error"],
        });
        return of(null);
      })
    );
  }

  @Action(SusbcribeToFCM)
  susbcribeToFCM(
    { dispatch, getState, patchState }: StateContext<User>,
    { firstInt }: SusbcribeToFCM
  ) {
    return this.fcm.requestPermission().pipe(
      tap((fcmToken) => {
        const { firebaseUser } = getState();
        const notification = firebaseUser.TavuelUser.NotificationDevices.find(
          (device) => device.Token_NotificationDevice === fcmToken
        );
        if (notification && firstInt) {
          patchState({
            devideAdded: notification.State_NotificationDevice,
          });
        } else {
          dispatch(new SuscribeToChatAdminNotifications(fcmToken));
        }
      }),
      trace("SusbcribeToFCM"),
      catchError((err) => {
        this.snackBar.open(err, "OK", {
          duration: 3000,
          panelClass: ["custom-snackbar-error"],
        });
        return of(null);
      })
    );
  }

  @Action(SuscribeToChatAdminNotifications)
  suscribeToChatAdminNotifications(
    { patchState, getState }: StateContext<User>,
    { fcmToken }: SuscribeToChatAdminNotifications
  ) {
    const token = this.store.selectSnapshot(UserState.token);
    const { firebaseUser } = getState();

    const notification = firebaseUser.TavuelUser.NotificationDevices.find(
      device => device.Token_NotificationDevice === fcmToken
    );
    const info = notification
      ? {
        id: notification.id,
        State_NotificationDevice: !!notification.State_NotificationDevice,
        Token_NotificationDevice: fcmToken
      }
      : {
        State_NotificationDevice: true,
        Token_NotificationDevice: fcmToken
      };
    return this.api.suscribeToChatAdminNotifications(token, info).pipe(
      tap(({ data: { notificationDevice } }) => {
        if (notificationDevice) {
          const clonedMe = cloneDeep(firebaseUser);
          if (info.id) {
            clonedMe.TavuelUser.NotificationDevices = clonedMe.TavuelUser.NotificationDevices.map(
              device =>
                device.id === notificationDevice.id
                  ? notificationDevice
                  : device
            );
          } else {
            clonedMe.TavuelUser.NotificationDevices.push(notificationDevice);
          }
          patchState({
            firebaseUser: clonedMe,
            devideAdded: notificationDevice
              ? info.State_NotificationDevice
              : true
          });
        }
      }),
      trace('SuscribeToNotification'),
    );
  }

  @Action(RegisterPerson)
  RegisterPerson(
    { patchState, getState }: StateContext<User>,
    { user }: RegisterPerson
  ) {
    const { token, firebaseUser } = getState();
    delete user.ConfirmPassword;
    return this.api.registerPerson(token, user).pipe(
      tap(
        ({
          data: {
            registerPerson: { TavuelUser },
          },
        }) => {
          if (TavuelUser) {
            firebaseUser.TavuelUser = TavuelUser;
            patchState({ firebaseUser });
          }
        }
      ),
      trace("RegisterPerson"),
      catchError((error) => {
        this.snackBar.open(error, "OK", {
          duration: 3000,
          panelClass: ["custom-snackbar-error"],
        });
        return of(null);
      })
    );
  }

  @Action(CreateAcount)
  CreateAcount({ dispatch }: StateContext<User>, { user }: CreateAcount) {
    delete user.ConfirmPassword;
    return this.api.registerUser(user).pipe(
      tap(() => {
        dispatch(new Login({ email: user.Email, password: user.Password }));
        this.snackBar.open("Cuenta creada", "😎", {
          duration: 3000,
          panelClass: ["custom-snackbar-error"],
        });
      }),
      trace("CreateAcount"),
      catchError(({ graphQLErrors }) => {
        const [{ message }] = graphQLErrors;
        this.snackBar.open(message, "🙄", {
          duration: 3000,
          panelClass: ["custom-snackbar-error"],
        });
        return of(null);
      })
    );
  }

  @Action(Login)
  async login({ dispatch }: StateContext<User>, { credentials }: Login) {
    const ghostUID = this.store.selectSnapshot(UserState.UID);
    const userCredentials = await this.afAuth.signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    );
    setTimeout(() => {
      dispatch(new Navigate(["/profile"]));
    }, 500);
    dispatch(new FirebaseAuth(userCredentials, ghostUID));
  }

  @Action(SendMessage)
  sendMessage(
    { getState, patchState }: StateContext<User>,
    { message }: SendMessage
  ) {
    return this.api.sendMessage(message).pipe(
      tap(({ data: { result } }) => {
      }),
      trace("SendMessage")
    );
  }

  readMessages = (user, data) => {
    if(user.id === (data.FK_Anonymous ? data.FK_Anonymous : data.FK_GoogleAuth).toString()){
      return user.Messages.map(message => {
        const messageAux = message;
        messageAux.Read = true;
        return messageAux; 
      });
    }
    return user;
  }

  @Action(ReadMessage)
  readMessage(
    { getState, patchState }: StateContext<User>,
    { data }: ReadMessage
  ) {
    return this.api.readMessage(data).pipe(
      tap(({ data: { result } }) => {
        const user = getState().UsersWithMessages;
        if(data.FK_Anonymous){
          user.UsersAnonymous.map(user => {
            return this.readMessages(user, data);
          });
        } else if(data.FK_GoogleAuth){
          user.UsersGoogleAuth.map(user => {
            return this.readMessages(user, data);
          });
        }
        patchState({ UsersWithMessages: user });
      }),
      trace("GetUsersWithMessages")
    );
  }

  @Action(GetUsersWithMessages)
  getUsersWithMessages(
    { patchState, getState }: StateContext<User>,
    {}: GetUsersWithMessages
  ) {
    if(getState().placeId){
      return this.api.getUsersWithMessages(getState().placeId).pipe(
        tap(({ data: { result } }) => {
          if (result.UsersAnonymous && result.UsersAnonymous.length > 0) {
            result.UsersAnonymous.forEach((anonymous) => {
              if(this.lastChatSocket){
                this.lastChatSocket.unsubscribe();
              }
              this.lastChatSocket = this.socket
                .fromEvent(`new-message-anonymous-${anonymous.id}-${getState().placeId}`)
                .subscribe((res: string) => {
                  this.store.dispatch(new AddMessage(res));
                });
            });
          }
          if (result.UsersGoogleAuth && result.UsersGoogleAuth.length > 0) {
            result.UsersGoogleAuth.forEach((user) => {
              if(this.lastChatSocket){
                this.lastChatSocket.unsubscribe();
              }
              this.lastChatSocket = this.socket
                .fromEvent(`new-message-user-${user.id}-${getState().placeId}`)
                .subscribe((res: string) => {
                  this.store.dispatch(new AddMessage(res));
                });
            });
          }
          patchState({ UsersWithMessages: result });
        }),
        trace("GetUsersWithMessages")
      );
    }
  }

  @Action(SelectUser)
  selectUser(
    { patchState }: StateContext<User>,
    { UserAnonymous, UserGoogleAuth }: SelectUser
  ) {
    patchState({
      UserSelected: {
        UserAnonymous,
        UserGoogleAuth,
      },
    });
    return of(null);
  }

  @Action(AddMessage)
  addMessage(
    { patchState, getState }: StateContext<User>,
    { message, isNew }: AddMessage
  ) {
    const { UsersWithMessages, UserSelected } = getState();
    const bytes = CryptoJS.AES.decrypt(message, environment.chatKey);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    if (isNew) {
      if (data.UserAnonymous && data.UserAnonymous.id) {
        if(this.lastChatSocket){
          this.lastChatSocket.unsubscribe();
        }
        this.lastChatSocket = this.socket
          .fromEvent(`new-message-anonymous-${data.UserAnonymous.id}-${getState().placeId}`)
          .subscribe((res: string) => {
            this.store.dispatch(new AddMessage(res));
          });
        patchState({
          UsersWithMessages: {
            UsersAnonymous: [
              data.UserAnonymous,
              ...UsersWithMessages.UsersAnonymous,
            ],
            UsersGoogleAuth: UsersWithMessages.UsersGoogleAuth,
          },
        });
      } else if (data.UserGoogleAuth && data.UserGoogleAuth.id) {
        if(this.lastChatSocket){
          this.lastChatSocket.unsubscribe();
        }
        this.lastChatSocket = this.socket
          .fromEvent(`new-message-user-${data.UserGoogleAuth.id}-${getState().placeId}`)
          .subscribe((res: string) => {
            this.store.dispatch(new AddMessage(res));
          });
        patchState({
          UsersWithMessages: {
            UsersAnonymous: UsersWithMessages.UsersAnonymous,
            UsersGoogleAuth: [
              {
                ...data.UserGoogleAuth,
                Messages: [data.Message],
              },
              ...UsersWithMessages.UsersGoogleAuth,
            ],
          },
        });
      }
    } else if (data.UserAnonymous && !data.UserGoogleAuth) {
      patchState({
        UsersWithMessages: {
          UsersAnonymous: UsersWithMessages.UsersAnonymous.map((anonymus) => {
            if (Number(anonymus.id) === Number(data.UserAnonymous.id)) {
              return {
                ...anonymus,
                Messages: [ data.Message, ...anonymus.Messages ],
              };
            } else {
              return anonymus;
            }
          }),
          UsersGoogleAuth: UsersWithMessages.UsersGoogleAuth,
        },
        UserSelected: UserSelected && UserSelected.UserAnonymous
          ? {
              UserGoogleAuth: null,
              UserAnonymous: {
                ...UserSelected.UserAnonymous,
                Messages: 
                UserSelected 
                && UserSelected.UserAnonymous 
                && Number(UserSelected.UserAnonymous.id) === Number(data.UserAnonymous.id) ? 
                [ data.Message, ...UserSelected.UserAnonymous.Messages ] 
                : 
                [ ...UserSelected.UserAnonymous.Messages ],
              },
            }
          : UserSelected,
      });
    } else if (!data.UserAnonymous && data.UserGoogleAuth) {
      patchState({
        UsersWithMessages: {
          UsersGoogleAuth: UsersWithMessages.UsersGoogleAuth.map((user) => {
            if (user.id === data.UserGoogleAuth.id) {
              return {
                ...user,
                Messages: [ data.Message, ...user.Messages ],
              };
            } else {
              return user;
            }
          }),
          UsersAnonymous: UsersWithMessages.UsersAnonymous,
        },
        UserSelected: UserSelected && UserSelected.UserGoogleAuth
          ? {
              UserGoogleAuth: {
                ...UserSelected.UserGoogleAuth,
                Messages: 
                UserSelected
                && UserSelected.UserGoogleAuth
                && UserSelected.UserGoogleAuth.id == data.UserGoogleAuth.id ?
                [ data.Message, ...UserSelected.UserGoogleAuth.Messages ]
                :
                [ ...UserSelected.UserGoogleAuth.Messages ],
              },
              UserAnonymous: null,
            }
          : UserSelected,
      });
    }
  }

  @Action(ResetPasswordEmail)
  ResetPasswordEmail({}: StateContext<User>, { email }: ResetPasswordEmail) {
    this.afAuth
      .sendPasswordResetEmail(email)
      .then((res) => {
        this.openSnackBar("Email enviado con exito", "OK", 3000);
      })
      .catch(() => {
        this.openSnackBar("Error enviar el email", "OK", 3000);
      });
  }

  @Action(getPlacesByUserAndModule)
  getPlacesByUserAndModule(
    { patchState, getState, dispatch }: StateContext<User>,
    {}:  getPlacesByUserAndModule
  ) {
      const { token, firebaseUser: { TavuelUser: { id } } } = getState();
      return this.api.getPlacesByUserAndModule(id, 3, token).pipe(
      tap(({ data: { places } }) => {
        patchState({Places : places});
        trace("getPlacesByUserAndModule")
      }),
    );
  }

  @Action(PlaceID)
  PlaceId(
    { patchState, dispatch, getState }: StateContext<User>,
    { placeId }: PlaceID
  ) {
    if (this.lastPlaceSocket) {
      this.lastPlaceSocket.unsubscribe();
    }
    this.lastPlaceSocket = this.socket
      .fromEvent(`new-message-${placeId}`)
      .subscribe((res: string) => {
        this.store.dispatch(new AddMessage(res, true));
      });
    patchState({ placeId });
    dispatch(new GetUsersWithMessages());
  }
}
