import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocPipe } from 'src/app/pipes/doc.pipe';
import { CoinFormatPipe } from '../pipes/coin-format/coin-format.pipe';
import { OrderDetailPipe } from '../pipes/order-detail/order-detail.pipe';
import { ProdTypeInstTypePipe } from '../pipes/prod-type-inst-type/prod-type-inst-type.pipe';
import { OrderDetailByTypePipe } from '../pipes/order-detail-by-type/order-detail-by-type.pipe';
import { DetailsPipe } from '../pipes/details/details.pipe';
import { ImgPipe } from '../pipes/img/img.pipe';
import { MessagePipe } from '../pipes/message/message.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { DateFormatFromPipe } from '../pipes/date-format-from/date-format-from.pipe';
const config: SocketIoConfig = { url: 'https://prod.tavuel.com', options: {}, };

@NgModule({
  declarations: [
    DocPipe,
    CoinFormatPipe,
    DetailsPipe,
    OrderDetailPipe,
    ProdTypeInstTypePipe,
    OrderDetailByTypePipe,
    ImgPipe,
    MessagePipe,
    DateFormatFromPipe,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatMenuModule,
    MatTooltipModule,
    MatDialogModule,
    MatTableModule,
    MatCheckboxModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatIconModule,
    MatSnackBarModule,
    MatButtonModule,
    MatExpansionModule,
    MatSelectModule,
    SocketIoModule.forRoot(config),
    MatDatepickerModule,
    MatSlideToggleModule,
    MatSidenavModule,
  ],
  exports: [
    DocPipe,
    CoinFormatPipe,
    OrderDetailPipe,
    ProdTypeInstTypePipe,
    OrderDetailByTypePipe,
    DetailsPipe,
    ImgPipe,
    MessagePipe,
    DateFormatFromPipe,
    MatIconModule,
    MatDialogModule,
    MatMenuModule,
    MatTooltipModule,
    MatTableModule,
    MatCheckboxModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatExpansionModule,
    MatSelectModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatSidenavModule,
  ],
})
export class HelpersModule { }
