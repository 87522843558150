import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { UserState } from './states/user.state';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { AdminGuard } from './guards/admin.guard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LocationService } from './services/location.service';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { ApolloClientsModule } from './apollo-clients-module';
import { ApiService } from './services/api.service';
import { FcmService } from './services/fcm.service';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { UIState } from './states/ui.state';
import { QuestionState } from './states/question.state';
import { HelpersModule } from './helpers/helpers.module';
import { DeviceDetectorModule } from 'ngx-device-detector';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    DeviceDetectorModule.forRoot(),
    HttpLinkModule,
    HttpClientModule,
    ApolloClientsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    NgxsModule.forRoot([
      UserState,
      UIState,
      QuestionState,
    ]),
    HelpersModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AngularFirePerformanceModule,
    AngularFireMessagingModule
  ],
  exports: [
  ],
  providers: [
    AdminGuard,
    ApiService,
    LocationService,
    FcmService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
