export const environment = {
  production: true,
  cryptoAES: 'xyXTvZQTcQRt5AN',
  apiPlaces: 'https://api.tavuel.com/places',
  p12Upload: 'http://localhost/api.php',
  api: 'https://api.tavuel.com/nodejs',
  apiChat: 'https://api.tavuel.com/eb/chat',
  chatKey: '89sdJKMm_asdjljjK299JASD_AJSD1233nnn',
  firebase : {
    apiKey: 'AIzaSyCBVV-BADea7PB_33t1dzx-h3KIpZsdwk0',
    authDomain: 'tavuel506.firebaseapp.com',
    databaseURL: 'https://tavuel506.firebaseio.com',
    projectId: 'tavuel506',
    storageBucket: 'tavuel506.appspot.com',
    messagingSenderId: '536950237048',
    appId: '1:536950237048:web:3c9094800aa0bab8'
  },
};
